import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Typography, FormControlLabel, Checkbox, Paper } from "@mui/material";
import { styled } from '@mui/system';

const domainRange = {
    day: [new Date(Date.now() - 24 * 60 * 60 * 1000).getTime(), Date.now()],
    week: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).getTime(), Date.now()],
    month: [new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).getTime(), Date.now()],
    year: [new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).getTime(), Date.now()]
};

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    borderRadius: '15px',
}));

const ChartContainer = styled('div')({
    width: '100%',
    height: '400px',
});

const tankColors = {
    t1: { volume: '#8884d8', ullage: '#4848d8', temp: '#d88484', height: '#d8a484' },
    t2: { volume: '#82ca9d', ullage: '#42aa7d', temp: '#ca8282', height: '#caa482' },
    t3: { volume: '#ffc658', ullage: '#ff9e28', temp: '#c85858', height: '#c8a858' },
    t4: { volume: '#e04141', ullage: '#a03131', temp: '#41e0e0', height: '#41e041' }
};

const hasData = (data, tank) => {
    return data.some(point => point[tank] && (point[tank].volume || point[tank].ullage || point[tank].temp || point[tank].height));
};

const ChartTab = ({ selectedTanks, handleTankCheck, data, range }) => {

    return (
        <div>
            <Typography variant="h3">Volume Vs. Ullage</Typography>
            <div>
                {["t1", "t2", "t3", "t4"].map(tank => (
                    hasData(data, tank) && (
                        <FormControlLabel
                            key={tank}
                            control={
                                <Checkbox
                                    checked={selectedTanks[tank]}
                                    onChange={() => handleTankCheck(tank)}
                                    name={tank}
                                    color="primary"
                                    style={{
                                        backgroundColor: selectedTanks[tank] ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                        color: selectedTanks[tank] ? '#ffffff' : '#000000',
                                    }}
                                />
                            }
                            label={
                                <>
                                    <span style={{ color: tankColors[tank].volume, marginRight: '5px' }}>●</span>
                                    <span style={{ color: tankColors[tank].ullage, marginRight: '5px' }}>●</span>
                                    {`Tank ${tank.toUpperCase()} (V, U)`}
                                </>
                            }
                        />
                    )
                ))}
            </div>
            <StyledPaper>
                <ChartContainer>
                    <ResponsiveContainer>
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey={item => new Date(`${item.system_date} ${item.system_time}`).getTime()}
                                tickFormatter={(value) => {
                                    const date = new Date(value);
                                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                                }}
                                domain={[domainRange[range][0], domainRange[range][1]]}
                                type="number"
                                angle={-45}
                                height={80}
                                interval={range === 'week' ? 4 : range === 'month' ? 24 : range === 'year' ? 124 : 0}
                                scale="time"
                                tick={{ fill: 'white' }}
                            />
                            <YAxis yAxisId="left" orientation="left" stroke="#8884d8" label={{ value: 'Volume (Gallons)', angle: -90, position: 'insideLeft' }} />
                            <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" label={{ value: 'Ullage (Gallons)', angle: 90, position: 'insideRight' }} />
                            <Tooltip />
                            {selectedTanks.t1 && <Line yAxisId="left" type="monotone" dataKey="t1.volume" stroke={tankColors.t1.volume} />}
                            {selectedTanks.t1 && <Line yAxisId="right" type="monotone" dataKey="t1.ullage" stroke={tankColors.t1.ullage} />}
                            {selectedTanks.t2 && <Line yAxisId="left" type="monotone" dataKey="t2.volume" stroke={tankColors.t2.volume} />}
                            {selectedTanks.t2 && <Line yAxisId="right" type="monotone" dataKey="t2.ullage" stroke={tankColors.t2.ullage} />}
                            {selectedTanks.t3 && <Line yAxisId="left" type="monotone" dataKey="t3.volume" stroke={tankColors.t3.volume} />}
                            {selectedTanks.t3 && <Line yAxisId="right" type="monotone" dataKey="t3.ullage" stroke={tankColors.t3.ullage} />}
                            {selectedTanks.t4 && <Line yAxisId="left" type="monotone" dataKey="t4.volume" stroke={tankColors.t4.volume} />}
                            {selectedTanks.t4 && <Line yAxisId="right" type="monotone" dataKey="t4.ullage" stroke={tankColors.t4.ullage} />}
                        </LineChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </StyledPaper>
            <Typography variant="h3">Temperature Vs. Height</Typography>
            <StyledPaper>
                <ChartContainer>
                    <ResponsiveContainer>
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey={item => new Date(`${item.system_date} ${item.system_time}`).getTime()}
                                tickFormatter={(value) => {
                                    const date = new Date(value);
                                    return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
                                }}
                                domain={[domainRange[range][0], domainRange[range][1]]}
                                type="number"
                                angle={-45}
                                height={80}
                                interval={range === 'week' ? 4 : range === 'month' ? 24 : range === 'year' ? 99 : 0}
                                scale="time"
                                tick={{ fill: 'white' }}
                            />
                            <YAxis yAxisId="left" orientation="left" stroke="#d88484" label={{ value: 'Temperature (°F)', angle: -90, position: 'insideLeft' }} />
                            <YAxis yAxisId="right" orientation="right" stroke="#d8a484" label={{ value: 'Height (inches)', angle: 90, position: 'insideRight' }} />
                            <Tooltip />
                            {selectedTanks.t1 && <Line yAxisId="left" type="monotone" dataKey="t1.temp" stroke={tankColors.t1.temp} />}
                            {selectedTanks.t1 && <Line yAxisId="right" type="monotone" dataKey="t1.height" stroke={tankColors.t1.height} />}
                            {selectedTanks.t2 && <Line yAxisId="left" type="monotone" dataKey="t2.temp" stroke={tankColors.t2.temp} />}
                            {selectedTanks.t2 && <Line yAxisId="right" type="monotone" dataKey="t2.height" stroke={tankColors.t2.height} />}
                            {selectedTanks.t3 && <Line yAxisId="left" type="monotone" dataKey="t3.temp" stroke={tankColors.t3.temp} />}
                            {selectedTanks.t3 && <Line yAxisId="right" type="monotone" dataKey="t3.height" stroke={tankColors.t3.height} />}
                            {selectedTanks.t4 && <Line yAxisId="left" type="monotone" dataKey="t4.temp" stroke={tankColors.t4.temp} />}
                            {selectedTanks.t4 && <Line yAxisId="right" type="monotone" dataKey="t4.height" stroke={tankColors.t4.height} />}
                        </LineChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </StyledPaper>
        </div>
    );
};

export default ChartTab;
