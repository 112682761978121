import { Box, Button, Typography, useTheme, Paper, Tab, Tabs } from "@mui/material";
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import useAuth from "../../../hooks/useAuth";

import {
  useGetATGGeneralTankDataQuery,
  useGetATGConfigDataQuery,
  useGetATGDeliveryDataQuery,
  useGetATGLeakDataQuery
} from "../../../features/atgs/atgsApiSlice";

import Header from "../global/Header";
import ReportsTab from "./tabs/ReportsTab";
import OverviewTab from './tabs/OverviewTab';
import AtgSettingsTab from './tabs/AtgSettingsTab';
import InventoryTab from "./tabs/InventoryTab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <span
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </span>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Atg = () => {
  const theme = useTheme();
  const params = useParams();
  const atg_name = params.atg_name;

  const [value, setValue] = React.useState(0);

  const { username, isManager, isAdmin } = useAuth();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Fetch all the necessary data
  const { data: generalData, isLoading: isGeneralLoading } = useGetATGGeneralTankDataQuery(atg_name);
  const { data: configData, isLoading: isConfigLoading } = useGetATGConfigDataQuery(atg_name);
  const { data: deliveryData, isLoading: isDeliveryLoading } = useGetATGDeliveryDataQuery(atg_name);
  const { data: leakData, isLoading: isLeakLoading } = useGetATGLeakDataQuery(atg_name);

  let content;
  
  // Handle loading and error states
  if (isGeneralLoading || isConfigLoading || isDeliveryLoading || isLeakLoading) {
    content = <PulseLoader color={"#FFF"} />;
  }

  if (generalData && configData && deliveryData && leakData) {
    const atg = generalData?.shadow;
    const config = configData?.shadow;
    const deliveries = deliveryData?.shadow;
    const leaks = leakData?.shadow;

    content = (
      <Box m="20px">
        <Box display="flex" justifyContent="end" mt="20px"></Box>
        <Header title={atg?.header_1 || "ATG"} subtitle="Manage ATG" />
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="scrollable auto tabs example"
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="ATG Settings" {...a11yProps(1)} />
            <Tab label="Reports" {...a11yProps(2)} />
            <Tab label="Inventory" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <OverviewTab atg={atg} atg_name={atg_name} deliveries={deliveries} leaks={leaks} config={config} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AtgSettingsTab atg={atg} config={config} atg_name={atg_name} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ReportsTab atg={atg} deliveries={deliveries} leaks={leaks} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <InventoryTab atg={atg} config={config} />
        </TabPanel>
      </Box>
    );
  }

  return content;
};

export default Atg;
