import React from 'react';
import { Badge, Box, Typography, Grid, Paper, Card, CardContent, LinearProgress, useTheme } from "@mui/material";
import { LocalGasStationOutlined, SpeedOutlined, LocalShippingOutlined, HelpOutline } from '@mui/icons-material';
import AlarmTab from './components/Alarms';
import { tokens } from '../../../../theme';

const formatTime = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
};

const formatDate = (dateString) => {
    // Manually split the date string to avoid any timezone conversion issues
    const [year, month, day] = dateString.split('-');

    // Create a new Date object with the local time
    const date = new Date(year, month - 1, day); // Month is 0-indexed in JavaScript Date

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options).replace(/(\d)(st|nd|rd|th)/, '$1$2');
};

const TankVolume = ({ volume, disabled }) => {
    const maxVolume = 12000;
    const filledPercentage = (volume / maxVolume) * 100;
    const labelPositions = {
        9000: 75,
        6000: 50,
        3000: 25
    };

    const tankStyles = {
        height: disabled ? "150px" : "300px",
        width: "70px",
        borderRadius: "25px",
        border: "3px solid black",
        position: "relative",
        overflow: "hidden",
        backgroundColor: disabled ? "#ccc" : "#eee",
        marginRight: "20px",
    };

    const volumeStyles = {
        height: `${filledPercentage}%`,
        width: "70px",
        position: "absolute",
        bottom: "0",
        right: "0",
        backgroundColor: disabled ? "#999" : "#FFA500",
        borderRadius: "22px 22px 0 0",
    };

    const labelStyle = {
        position: "absolute",
        left: "10px",
        transform: "translateY(50%)",
        color: disabled ? "#999" : "black"
    };

    return (
        <div style={tankStyles}>
            <div style={volumeStyles}></div>
            {Object.entries(labelPositions).map(([gallon, position]) => (
                <div key={gallon} style={{ ...labelStyle, bottom: `${position}%` }}>
                    <span>{gallon}</span>
                    <span style={{ display: "inline-block", width: "15px", borderBottom: "1px solid black", marginLeft: "5px" }}></span>
                </div>
            ))}
            <div style={{ ...labelStyle, bottom: "5px" }}>0</div>
            <div style={{ ...labelStyle, top: "5px" }}>{maxVolume}</div>
        </div>
    );
};

const TempBar = ({ temperature, disabled }) => {
    const maxTemp = 110;
    const tempPercentage = (temperature / maxTemp) * 100;
    const bgColor = `linear-gradient(to right, blue ${tempPercentage}%, red)`;

    return (
        <div style={{ position: "relative" }}>
            <LinearProgress
                variant="determinate"
                value={tempPercentage}
                sx={{
                    height: "20px",
                    width: "90%",
                    borderRadius: "5px",
                    background: disabled ? "#ccc" : "#eee",
                    "& .MuiLinearProgress-bar": {
                        backgroundImage: disabled ? "#999" : bgColor
                    }
                }}
            />
            <div style={{ position: "absolute", right: "100%", top: "0", paddingRight: "5px", color: disabled ? "#999" : "black" }}>0°F</div>
            <div style={{ position: "absolute", left: "90%", bottom: "0", paddingLeft: "5px", paddingRight: "5px", color: disabled ? "#999" : "black" }}>110°F</div>
        </div>
    );
};

const TankInfoCard = ({ tankData, name, fuelType, disabled }) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const formatFuelType = (fuelType) => fuelType ? fuelType.charAt(0).toUpperCase() + fuelType.slice(1).toLowerCase() : '';

    const getFuelTypeIcon = (fuelType) => {
        switch (formatFuelType(fuelType)) {
            case 'Unleaded': return <LocalGasStationOutlined sx={{ fontSize: 35, ml: 8, color: disabled ? "#999" : "#fff" }} />;
            case 'Supreme':
            case 'Super': return <SpeedOutlined sx={{ fontSize: 35, ml: 8, color: disabled ? "#999" : "#fff" }} />;
            case 'Diesel': return <LocalShippingOutlined sx={{ fontSize: 35, ml: 8, color: disabled ? "#999" : "#fff" }} />;
            default: return <HelpOutline sx={{ fontSize: 35, ml: 8, color: disabled ? "#999" : "#fff" }} />;
        }
    };

    return (
        <Card elevation={3} sx={{ backgroundColor: disabled ? "#666" : "#424242" }}>
            <CardContent>
                <Box display="flex" flexDirection="column" gap={3}>
                    <Box display="flex" alignItems="center"> 
                        <Typography variant={disabled ? "h5" : "h3"} gutterBottom flexShrink={0} sx={{ marginRight: 10, color: disabled ? "#999" : "#fff" }}>
                            {name}
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1}>
                            {fuelType && (
                                <Badge
                                    badgeContent={formatFuelType(fuelType)}
                                    color="primary"
                                    sx={{
                                        '.MuiBadge-badge': {
                                            fontSize: '1.5rem',
                                            height: 'auto',
                                            borderRadius: '12px',
                                            px: 1.5,
                                            backgroundColor: disabled ? "#999" : "#1976d2",
                                            color: disabled ? "#666" : "#fff",
                                        }
                                    }}
                                    overlap="rectangular"
                                />
                            )}


                        </Box>
                        {getFuelTypeIcon(fuelType)}
                        {disabled && <Badge
                            badgeContent={formatFuelType("Disabled")}
                            color="primary"
                            sx={{
                                '.MuiBadge-badge': {
                                    fontSize: '1.2rem',
                                    height: 'auto',
                                    borderRadius: '12px',
                                    px: 1.5,
                                    backgroundColor: colors.redAccent[600],
                                    color: colors.primary[100],
                                }
                            }}
                            overlap="rectangular"
                        /> }
                    </Box>
                    <Box display="flex" gap={3}>
                        <TankVolume volume={tankData.volume} disabled={disabled} />
                        <Box>
                            <Typography variant="h6" color={disabled ? "#999" : "#fff"}>Volume: {tankData.volume} gallons</Typography>
                            <Typography variant="body1" color={disabled ? "#999" : "#fff"}>Ullage: {tankData.ullage}</Typography>
                            <Typography variant="body1" color={disabled ? "#999" : "#fff"}>TC Volume: {tankData.tc_volume}</Typography>
                            <Typography variant="body1" color={disabled ? "#999" : "#fff"}>Water Volume: {tankData.water_volume}</Typography>
                            <Typography variant="body1" color={disabled ? "#999" : "#fff"}>Water Height: {tankData.water_height}</Typography>
                            <TempBar temperature={tankData.temperature} disabled={disabled} />
                            <Typography variant="body1" color={disabled ? "#999" : "#fff"}>Temp: {tankData.temperature}°F</Typography>
                        </Box>

                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};


const OverviewTab = ({ atg, atg_name, config, leaks }) => {
    const fuelTypes = Object.keys(config.tank_config).reduce((acc, tankKey) => {
        acc[tankKey] = config.tank_config[tankKey].product_label; // Get fuel type from config
        return acc;
    }, {});

    const isNonZeroTank = (tankData) => {
        return tankData && Object.values(tankData).some(value => value !== 0);
    };

    return (
        <Paper elevation={2} style={{ padding: '20px' }}>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                Local System Time: {formatTime(atg.system_time)}
            </Typography>
            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                System Date: {formatDate(atg.system_date)}
            </Typography>
            <AlarmTab atg_name={atg_name} />
            <Grid container spacing={3} justifyContent="space-evenly">
                {Object.keys(atg.tanks).map((tankKey, index) => {
                    const tankData = atg.tanks[tankKey].tank_data[0]; // Get first entry in tank_data array
                    const tankConfig = config.tank_config[tankKey];  // Get the config for the tank
                    const isDisabled = tankConfig.config_flag_raw === "0";  // Check if tank should be disabled

                    return isNonZeroTank(tankData) || isDisabled ? (
                        <Grid item xs={12} md={6} key={index}>
                            <TankInfoCard
                                tankData={tankData}
                                name={`Tank ${tankKey.replace('tank_', '')}`}
                                fuelType={fuelTypes[tankKey]}
                                disabled={isDisabled}
                            />
                        </Grid>
                    ) : null;
                })}
            </Grid>
        </Paper>
    );
};

export default OverviewTab;
