import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    useTheme,
    Grid,
    Paper,
    TextField,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Box,
    FormControlLabel,
    Checkbox,
    Radio,
    MenuItem,
    Select,
    IconButton,
} from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import { tokens } from '../../../../../theme';

import { useGetActiveJobsQuery } from '../../../../../features/atgs/atgsApiSlice';

import ChangeHeaders from '../forms/ChangeHeaders';
import ClearAlarms from '../forms/ClearAlarms';

import useAuth from '../../../../../hooks/useAuth';

const JobsDispatch = ({ atg, atg_name }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { username, role, organization_id, authorizedATGs } = useAuth();

    const buttons = role === 'Admin' ? ['Change Headers', 'Clear Alarms'] : ['Change Headers'];
    const [selectedButton, setSelectedButton] = useState(null);

    const {
        data: activeJobs = [],
        isLoading,
        isError,
        isSuccess,
        error
    } = useGetActiveJobsQuery(atg_name);

    const rows = Array.isArray(activeJobs.jobs) ? activeJobs.jobs.map(job => ({
        id: job.jobId,
        job: job.jobExecutionSummary.status  // Extracting status as a sample property
    })) : [];

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'job', headerName: 'Active Job', width: 200 }
    ];

    return (
        <Grid container spacing={3}>
            {/* Left Sidebar */}
            <Grid item xs={3}>
                <Paper elevation={2} style={{ padding: '20px' }}>
                    {buttons.map((button, index) => (
                        <Button
                            variant={selectedButton === index ? 'contained' : 'outlined'}
                            color="secondary"
                            onClick={() => setSelectedButton(index)}
                            style={{ margin: '10px' }}
                            key={index}
                        >
                            {button}
                        </Button>
                    ))}
                </Paper>
            </Grid>

            {/* Right Section */}
            <Grid item xs={9}>
                <Grid container direction="column" spacing={3}>
                    {/* Top Right Widget */}
                    <Grid item xs={12}>
                        <Paper elevation={2} style={{ padding: '20px' }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h4" style={{ marginBottom: '10px' }} color={colors.greenAccent[300]}>
                                        Settings for {buttons[selectedButton]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {selectedButton === 0 && <ChangeHeaders atg={atg} atg_name={atg_name} />}
                                    {selectedButton === 1 && role === 'Admin' && <ClearAlarms atg={atg} atg_name={atg_name} />}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    {/* Bottom Right Widget */}
                    <Grid item xs={12}>
                        <Paper elevation={2} style={{ padding: '20px', height: 420 }}>
                            <Typography variant="h4" color={colors.greenAccent[300]}>
                                Active Jobs
                            </Typography>

                            {isLoading && <Typography>Loading...</Typography>}
                            {isError && <Typography color="error">Error fetching jobs: {error?.message}</Typography>}
                            {isSuccess && <DataGrid rows={rows} columns={columns} pageSize={5} style={{ height: '300px' }} />}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default JobsDispatch;
